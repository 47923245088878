import styled from 'styled-components'

import { colors, dimensions } from '#styles'

export const Backdrop = styled.div<{ open: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  color: ${colors.white};
  z-index: 1000;
  backdrop-filter: blur(5px);
  background-color: ${colors.blackTransparent};
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const CloseButton = styled.button`
  top: 2rem;
  right: 2rem;
  position: fixed;
  border-radius: 10rem;
  border: solid;
  cursor: pointer;
  display: flex;
  color: ${colors.white};
`

export const StyledVideo = styled.video`
  width: 320px;
  height: 240px;
  @media (min-width: ${dimensions.tablet}) {
    width: 640px;
    height: 480px;
  }
`
