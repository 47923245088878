import { StaticImageData } from 'next/image'
import React, { FC, ReactNode } from 'react'

import { dimensions } from '#styles'

import { StyledImage } from '../../../components/common/styled'
import * as S from './styled'

type Props = {
  children: ReactNode
  image: StaticImageData
  solutionPros: {
    header: string
    body: string
  }[]
}

const BestChargingSolution: FC<Props> = ({ children, image, solutionPros }) => {
  const solutionList = solutionPros.map(solution => (
    <S.SolutionList key={solution.header}>
      <S.ListHeader>{solution.header}</S.ListHeader>
      <S.ListBody>{solution.body}</S.ListBody>
    </S.SolutionList>
  ))
  return (
    <>
      {children}
      <S.SolutionGrid>
        <S.ImageContainer
          style={{ alignSelf: 'center', justifySelf: 'center' }}
        >
          <StyledImage
            src={image}
            alt="charging-image"
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, (min-width: ${dimensions.tablet}) 60vw, 100vw`}
          />
        </S.ImageContainer>

        <S.SolutionListWrapper>{solutionList}</S.SolutionListWrapper>
      </S.SolutionGrid>
    </>
  )
}

export default BestChargingSolution
