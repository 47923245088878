import React, { FC } from 'react'

import * as S from './styled'

type Props = {
  pitches: {
    header: string
    body: string
  }[]
}

const SetupPitches: FC<Props> = ({ pitches }) => {
  return (
    <>
      {pitches.map(pitch => (
        <S.PitchWrapper key={pitch.header}>
          <S.PitchHeader>{pitch.header}</S.PitchHeader>
          <S.PitchBodyWrapper>
            <S.PitchBody>{pitch.body}</S.PitchBody>
          </S.PitchBodyWrapper>
        </S.PitchWrapper>
      ))}
    </>
  )
}

export default SetupPitches
