import Image from 'next/image'
import React, { FC } from 'react'

import close from '../../../../public/assets/common/close.svg'
import * as S from './styled'

type Props = {
  open: boolean
  handleClose: () => void
  // videoUrl for the video tag sort of originates from "./assets/"
  videoUrl: string
  className?: string
}

const VideoModal: FC<Props> = ({ open, handleClose, videoUrl, className }) => {
  if (!open) return null
  return (
    <S.Backdrop open={open} onClick={handleClose}>
      <S.CloseButton onClick={handleClose} type="button">
        <Image src={close} height={40} width={40} alt="" />
      </S.CloseButton>

      <S.StyledVideo
        autoPlay
        muted
        controls
        onClick={e => e.stopPropagation()}
        className={className}
      >
        <source src={`${videoUrl}.webm`} type="video/webm" />
        <source src={`${videoUrl}.mp4`} type="video/mp4" />
      </S.StyledVideo>
    </S.Backdrop>
  )
}

export default VideoModal
