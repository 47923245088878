import styled, { css } from 'styled-components'

import Container from '../../components/Container'

export const LocalizedContainer = styled(Container)`
  gap: 3rem;
`

export const EllipsisText = css`
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;
`
