import { useTranslation } from 'react-i18next'

export const useLinks = () => {
  const { t } = useTranslation('navigation')

  const links = {
    home: {
      section_2: {
        link: t('home.section-2.link'),
        meta: null,
      },
      section_3: {
        link: t('home.section-3.link'),
        meta: null,
      },
      section_4: {
        link: t('home.section-4.link'),
        meta: null,
      },
    },
    home_localized: {
      section_1: {
        link: t('home-localized.section-1.link'),
        meta: null,
      },
      section_4: {
        airports: {
          link: t('home-localized.section-4.airports.link'),
          meta: null,
        },
        restaurants: {
          link: t('home-localized.section-4.restaurants.link'),
          meta: null,
        },
        bars: {
          link: t('home-localized.section-4.bars.link'),
          meta: null,
        },
        malls: {
          link: t('home-localized.section-4.malls.link'),
          meta: null,
        },
        arenas: {
          link: t('home-localized.section-4.arenas.link'),
          meta: null,
        },
        venues: {
          link: t('home-localized.section-4.venues.link'),
          meta: null,
        },
      },
      section_5: {
        link: t('home-localized.section-5.link'),
        meta: null,
      },
      section_6: {
        link: t('home-localized.section-6.link'),
        meta: null,
      },
      section_7: {
        link: t('home-localized.section-7.link'),
        meta: null,
      },
      section_8: {
        link: t('home-localized.section-8.link'),
        meta: null,
      },
    },

    brick_for_brands: {
      section_1: {
        link: t('brick-for-brands.section-1.link'),
        meta: null,
      },
      section_2: {
        link: t('brick-for-brands.section-2.link'),
        meta: null,
      },
      section_4: {
        card_1: {
          link: t('brick-for-brands.section-4.card-1.link'),
          meta: null,
        },
        card_2: {
          link: t('brick-for-brands.section-4.card-2.link'),
          meta: null,
        },
        card_3: {
          link: t('brick-for-brands.section-4.card-3.link'),
          meta: null,
        },
      },
    },
    get_a_station: {
      banner_1: {
        link: t('get-a-station.banner-1.link'),
        meta: null,
      },
      section_1: {
        link: t('get-a-station.section-1.link'),
        meta: null,
      },
      section_2: {
        link: t('get-a-station.section-2.link'),
        meta: null,
      },
      section_4: {
        link: t('get-a-station.section-4.link'),
        meta: null,
      },
      section_5: {
        link: t('get-a-station.section-5.link'),
        meta: null,
      },
      section_6: {
        link: t('get-a-station.section-6.link'),
        meta: null,
      },
    },
    partnership: {
      pricing_cards: {
        card_1: {
          link: t('partnership.pricing-cards.card-1.link'),
          meta: null,
        },
        card_2: {
          link: t('partnership.pricing-cards.card-2.link'),
          meta: null,
        },
      },
      index: {
        section_1: {
          link: t('partnership.index.section-1.link'),
          meta: null,
        },
        section_9: {
          link: t('partnership.index.section-9.link'),
          meta: null,
        },
        section_10: {
          link: t('partnership.index.section-10.link'),
          meta: null,
        },
        section_11: {
          link: t('partnership.index.section-11.link'),
          meta: null,
        },
        section_12: {
          link: t('partnership.index.section-12.link'),
          meta: null,
        },
        section_2: {
          link: t('partnership.index.section-1.link'),
          meta: null,
        },
        section_3: {
          card_1: {
            link: t('partnership.index.section-3.card-1.link'),
            meta: null,
          },
          card_2: {
            link: t('partnership.index.section-3.card-2.link'),
            meta: null,
          },
        },
        section_6: {
          link: t('partnership.index.section-6.link'),
          meta: null,
        },
        section_7: {
          link: t('partnership.index.section-7.link'),
          meta: null,
        },
        section_8: {
          link: t('partnership.index.section-8.link'),
          meta: null,
        },
      },
      products: {
        banner_1: {
          link: t('partnership.index.section-6.link'),
          meta: null,
        },
        section_1: {
          link: t('partnership.index.section-6.link'),
          meta: null,
        },
        section_4: {
          link: t('partnership.index.section-6.link'),
          meta: null,
        },
      },
      why: {
        section_1: {
          link: t('partnership.why.section-1.link'),
          meta: null,
        },
        section_2: {
          card_1: {
            link: t('partnership.why.section-2.card-1.link'),
            meta: null,
          },
          card_2: {
            link: t('partnership.why.section-2.card-2.link'),
            meta: null,
          },
        },
        section_3: {
          link: t('partnership.why.section-3.link'),
          meta: null,
        },
        section_4: {
          link: t('partnership.why.section-4.link'),
          meta: null,
        },
      },
    },
    rent: {
      banner_1: {
        link: t('rent.banner-1.link'),
        meta: null,
      },
      section_3: {
        link: t('rent.section-3.link'),
        meta: null,
      },
      section_4: {
        link: t('rent.section-4.link'),
        meta: null,
      },
    },
    solutions: {
      airports: {
        banner_1: {
          link: t('solutions.airports.banner-1.link'),
          meta: null,
        },
        section_1: {
          link: t('solutions.airports.section-1.link'),
          meta: null,
        },
        section_2: {
          link: t('solutions.airports.section-2.link'),
          meta: null,
        },
        section_5: {
          link: t('solutions.airports.section-5.link'),
          meta: null,
        },
      },
      arenas: {
        banner_1: {
          link: t('solutions.arenas.banner-1.link'),
          meta: null,
        },
        section_1: {
          link: t('solutions.arenas.section-1.link'),
          meta: null,
        },
        section_2: {
          link: t('solutions.arenas.section-2.link'),
          meta: null,
        },
        section_5: {
          link: t('solutions.arenas.section-5.link'),
          meta: null,
        },
      },
      bars: {
        banner_1: {
          link: t('solutions.bars.banner-1.link'),
          meta: null,
        },
        section_1: {
          link: t('solutions.bars.section-1.link'),
          meta: null,
        },
        section_2: {
          link: t('solutions.bars.section-2.link'),
          meta: null,
        },
        section_5: {
          link: t('solutions.bars.section-5.link'),
          meta: null,
        },
      },
      malls: {
        banner_1: {
          link: t('solutions.malls.banner-1.link'),
          meta: null,
        },
        section_1: {
          link: t('solutions.malls.section-1.link'),
          meta: null,
        },
        section_2: {
          link: t('solutions.malls.section-2.link'),
          meta: null,
        },
        section_5: {
          link: t('solutions.malls.section-5.link'),
          meta: null,
        },
      },
      restaurants: {
        banner_1: {
          link: t('solutions.restaurants.banner-1.link'),
          meta: null,
        },
        section_1: {
          link: t('solutions.restaurants.section-1.link'),
          meta: null,
        },
        section_2: {
          link: t('solutions.restaurants.section-2.link'),
          meta: null,
        },
        section_5: {
          link: t('solutions.restaurants.section-5.link'),
          meta: null,
        },
      },
      venues: {
        banner_1: {
          link: t('solutions.venues.banner-1.link'),
          meta: null,
        },
        section_1: {
          link: t('solutions.venues.section-1.link'),
          meta: null,
        },
        section_2: {
          link: t('solutions.venues.section-2.link'),
          meta: null,
        },
        section_5: {
          link: t('solutions.venues.section-5.link'),
          meta: null,
        },
      },
    },
    b2b: {
      section_1: {
        link: t('b2b.section-1.link'),
        meta: null,
      },
      section_4: {
        link: t('b2b.section-4.link'),
        meta: null,
      },
      section_5: {
        airports: {
          link: t('b2b.section-5.airports.link'),
          meta: null,
        },
        restaurants: {
          link: t('b2b.section-5.restaurants.link'),
          meta: null,
        },
        bars: {
          link: t('b2b.section-5.bars.link'),
          meta: null,
        },
        malls: {
          link: t('b2b.section-5.malls.link'),
          meta: null,
        },
        arenas: {
          link: t('b2b.section-5.arenas.link'),
          meta: null,
        },
        venues: {
          link: t('b2b.section-5.venues.link'),
          meta: null,
        },
      },
      section_6: {
        link: t('b2b.section-6.link'),
        meta: null,
      },
    },
  }

  return links
}
