import type { NextPage } from 'next'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { i18n } from '../../next-i18next.config'
import { PageWrapper } from '../components/common/styled'
import HeadComp from '../components/HeadComp/HeadComp'
import { fetchForceAppless } from '../lib/api/market'
import { Paths } from '../lib/constants'
import { createLangHrefs } from '../lib/createLangHrefs'
import { formatServerSideTranslations, ignoreLocale } from '../lib/i18n'
import { PageProps } from '../types/pages'
import HomeGeneral from './home/HomeGeneral'
import HomeLocalized from './home/HomeLocalized'
import { getLocalizedCountryName } from './home/utils'

type Props = PageProps & {
  locale: string
}

const Home: NextPage<Props> = ({ langHrefs, applessOnly, locale }) => {
  const country = getLocalizedCountryName(locale)
  const { t } = useTranslation('home')
  return (
    <PageWrapper>
      <HeadComp
        title={t('head.title')}
        description={t('head.description')}
        langHrefs={langHrefs}
      />
      {!country ? (
        <HomeGeneral applessOnly={applessOnly} />
      ) : (
        <HomeLocalized country={country} />
      )}
    </PageWrapper>
  )
}

export const getStaticProps: GetStaticProps<PageProps> = async ({
  locale = i18n.defaultLocale,
  locales,
}) => {
  return {
    props: {
      ...(await formatServerSideTranslations(locale, [
        'common',
        'navigation',
        'home',
        'home-localized',
      ])),
      langHrefs: createLangHrefs(Paths.HOME, locale, locales),
      locale,
      applessOnly: await fetchForceAppless(locale),
      button: 'APP',
    },
    notFound: ignoreLocale(locale, ['default']),
    revalidate: 60 * 60,
  }
}

export default Home
