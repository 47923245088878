import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

import { colors, dimensions } from '#styles'

import Chevron from '../../../public/assets/common/chevron.svg'
import Play from '../../../public/assets/common/play.svg'
import GeoLoc from '../../../public/assets/Franchise/partnership-network.png'
import TiltedBrick from '../../../public/assets/GetABrick/two-brick-power-banks.png'
import WorkplaceBrick from '../../../public/assets/WorkPlaceBrick/people-using-power-bank-at-work.png'
import BrickMap from '../../../public/assets/world-map.png'
import Button from '../../components/common/Button/Button'
import * as Hero from '../../components/common/Hero'
import LinkButton from '../../components/common/LinkButton/LinkButton'
import NavButton from '../../components/common/NavButton/NavButton'
import { ShadowedImage, StyledImage } from '../../components/common/styled'
import VideoModal from '../../components/common/VideoModal/VideoModal'
import Container from '../../components/Container'
import { getAppLink } from '../../lib/helpers'
import { useLinks } from 'src/lib/hooks/useLinks'

type Props = {
  applessOnly: boolean
}

const HomeGeneral: FC<Props> = ({ applessOnly }) => {
  const { t } = useTranslation('home')
  const { t: tloc } = useTranslation('home-localized')

  const links = useLinks()

  const [videoOpen, setVideoOpen] = useState(false)
  return (
    <>
      <Container
        className="column laptop-row-reverse"
        backgroundColor={colors.black}
      >
        <Hero.ImageWrapper>
          <StyledImage
            src={GeoLoc}
            priority
            quality={40}
            alt="Image of charging locations in a map"
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 30vw,(min-width: ${dimensions.tablet}) 50vw, 80vw`}
          />
        </Hero.ImageWrapper>
        <Hero.TextContainer gap="1rem">
          <Hero.TextHeader color={colors.white} size="lg">
            {t('dont_buy.header')}
          </Hero.TextHeader>
          <Hero.TextBody color={colors.white}>
            {t('dont_buy.body')}
          </Hero.TextBody>
          <Hero.ButtonContainer padding="1rem 0 0 0" justifyStart>
            <LinkButton
              href={getAppLink(applessOnly)}
              id="primary-download_app-banner"
            >
              {t('dont_buy.link_app')}
            </LinkButton>
            <Button
              id={'secondary-play_video-banner'}
              transparent
              textColor={colors.greenAccent}
              onClick={() => setVideoOpen(true)}
            >
              <Image priority alt={tloc('hero.chevron_alt')} src={Play} />
              {tloc('hero.what_is_link')}
            </Button>
          </Hero.ButtonContainer>
        </Hero.TextContainer>
        <Video
          open={videoOpen}
          handleClose={() => setVideoOpen(false)}
          videoUrl="./assets/Videos/what-is-brick-power-bank-square-1080"
        />
      </Container>

      <Container
        className="column laptop-row"
        backgroundColor={colors.neutral50}
      >
        <Hero.ImageWrapper squareImage>
          <Image
            src={TiltedBrick}
            priority
            quality={40}
            alt={t('get_a_brick.image_alt')}
            style={{ width: '100%', height: 'auto' }}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
          />
        </Hero.ImageWrapper>
        <Hero.TextContainer>
          <Hero.TextHeader>{t('get_a_brick.header')}</Hero.TextHeader>
          <Hero.TextBody>{t('get_a_brick.body')}</Hero.TextBody>
          <NavButton
            href={links.home.section_2.link}
            transparent
            textColor={colors.redTorch}
            id="secondary-link_internal-get_brick"
          >
            {t('get_a_brick.link')}
            <Image alt="chevron arrow" src={Chevron} priority />
          </NavButton>
        </Hero.TextContainer>
      </Container>
      {/* FOLD */}
      <Container className="column laptop-row">
        <Hero.ImageWrapper>
          <ShadowedImage
            src={WorkplaceBrick}
            alt={t('solve_problem.image_alt')}
            sizes={`(min-width: ${dimensions.laptop}) 30vw,(min-width: ${dimensions.tablet}) 50vw, 80vw`}
            shadowWidth="0.5rem"
            shadowColor={colors.orangeAccent}
          />
        </Hero.ImageWrapper>
        <Hero.TextContainer>
          <Hero.TextHeader>{t('solve_problem.header')}</Hero.TextHeader>
          <Hero.TextBody>{t('solve_problem.body')}</Hero.TextBody>
          <NavButton
            href={links.home.section_3.link}
            transparent
            textColor={colors.redTorch}
            id="secondary-link_internal-get_station"
          >
            {t('solve_problem.link')}
            <Image alt={t('solve_problem.chevron_alt')} src={Chevron} />
          </NavButton>
        </Hero.TextContainer>
      </Container>
      <Container
        className="column"
        backgroundColor={colors.neutral50}
        style={{ alignItems: 'flex-start' }}
      >
        <Hero.TextContainer>
          <Hero.TextHeader>{t('join_brick_world.header')}</Hero.TextHeader>
          <Hero.TextBody>
            {t('join_brick_world.body_1')}
            <Hero.SemiBold>{t('join_brick_world.body_2')}</Hero.SemiBold>
          </Hero.TextBody>
          <NavButton
            href={links.home.section_4.link}
            transparent
            textColor={colors.redTorch}
            id="secondary-link_internal-partnership"
          >
            {t('join_brick_world.link')}
            <Image alt={t('join_brick_world.chevron_alt')} src={Chevron} />
          </NavButton>
        </Hero.TextContainer>
        <div style={{ width: '100%' }}>
          <Image
            src={BrickMap}
            alt={t('join_brick_world.image_alt')}
            style={{ width: '100%', height: 'auto' }}
            sizes="80vw"
            placeholder="blur"
          />
        </div>
      </Container>
    </>
  )
}

const Video = styled(VideoModal)`
  width: 320px;
  height: 320px;
  @media (min-width: ${dimensions.tablet}) {
    width: 640px;
    height: 640px;
  }
`

export default HomeGeneral
