const TranslatedLocalizedCountries: Record<string, string> = {
  SE: 'Sverige',
  HU: 'Magyarország',
  IE: 'Ireland',
  CA: 'Canada',
  KE: 'Kenya',
  RS: 'Srbija',
} as const

const LocalizedCountriesEnglish: Record<string, string> = {
  SE: 'Sweden',
  HU: 'Hungary',
  IE: 'Ireland',
  CA: 'Canada',
  KE: 'Kenya',
  RS: 'Serbia',
} as const

export const getLocalizedCountryName = (locale: string) => {
  const language = locale.slice(0, 2).toUpperCase()
  const country = locale.slice(3, 5).toUpperCase()
  return language === 'en'
    ? LocalizedCountriesEnglish[country]
    : TranslatedLocalizedCountries[country]
}
