import styled from 'styled-components'

import { colors, dimensions, typography } from '#styles'

export const PitchWrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 0.5rem;
  color: ${colors.white};
  max-width: 23rem;
  @media (min-width: ${dimensions.laptop}) {
    align-self: baseline;
  }
`

export const PitchHeader = styled.h2`
  text-align: center;
  letter-spacing: -0.9px;
  ${typography.displaySm.bold};
  @media (min-width: ${dimensions.tablet}) {
    ${typography.displayMd.bold};
  }
`

export const PitchBodyWrapper = styled.div`
  max-width: 16.875rem;
  padding: 0 0.5rem;
`

export const PitchBody = styled.p`
  ${typography.bodyLg.regular}
  text-align: center;
`
