import styled from 'styled-components'

import { dimensions, imageSizes, typography } from '#styles'

import { ImageWrapper } from '../../../components/common/Hero'

export const ImageContainer = styled(ImageWrapper)`
  width: ${imageSizes.section.full};
  @media (min-width: ${dimensions.tablet}) {
    width: ${imageSizes.section.laptop};
  }
`

export const SolutionListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  list-style-type: circle !important;
  margin-top: 1rem;
  @media (min-width: ${dimensions.laptop}) {
    margin-top: 0;
  }
`

export const SolutionList = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  @media (min-width: ${dimensions.tablet}) {
    max-inline-size: 37rem;
    padding-inline: 5.4rem;
  }
  @media (min-width: ${dimensions.laptop}) {
    align-items: flex-start;
    text-align: left;
    padding-inline: 0;
  }
`

export const ListHeader = styled.h2`
  ${typography.displayXs.bold};

  @media (min-width: ${dimensions.laptop}) {
    ${typography.displaySm.bold};
  }
`

export const ListBody = styled.p`
  ${typography.bodyMd.regular};

  @media (min-width: ${dimensions.laptop}) {
    ${typography.bodyLg.regular};
    padding-inline: 0;
  }
`

export const SolutionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${dimensions.laptop}) {
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
`
